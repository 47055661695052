$border-color: #b7b7b7;

.print-page {
  position: relative;
  background-color: #fff;
  width: 595px;
  min-height: 842px;
  margin: 0;
  padding: 25px 25px;
  font-size: 10px;
  line-height: 1.25;
  &.wide {
    width: 1240px;
    height: 1753px;
    padding: 45px 45px;
  }
  table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: text-top;
    background: transparent;
  }
  .print-title {
    font-size: 16px;
    font-weight: bold;
  }
  .print-table-wrap {
    //border-top: 2px solid #000;
    //border-left: 1px solid #000;
    //border-right: 1px solid #000;
    //border-bottom: 1px solid #000;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show;
  }
  th, td {
    border-bottom: 1px solid $border-color;
    border-left: 1px solid $border-color;
    border-spacing: 0;
    padding: 10px;
    vertical-align: top;
    text-align: left;
    word-break: break-word;
  }
  tr:first-child th, tr:first-child td {border-top: 1px solid $border-color;}
  th:last-child, td:last-child { border-right: 1px solid $border-color; }
  th {
    font-size: 11px;
    text-align: center;
    vertical-align: middle;
    font-weight: normal;
    white-space: nowrap;
  }
  .top-no tr:first-child th, tr:first-child td {border-top: 0;}
  .left-no {border-left: 0}
  .bot-no {border-bottom: 0}
  .text-bold {font-weight: bold;}
  .text-right {text-align: right;}
  .text-left {text-align: left;}
  .text-center {text-align: center;}
  @for $i from 1 to 9 {
    .pw-#{$i * 10}{ width: ($i * 10%); }
    .pw-#{$i * 10 + 5}{ width: ($i * 10% + 5%); }
    .w-#{$i * 10} {width: ($i * 10px);}
    .mt-#{$i * 10} {margin-top: ($i * 10px);}
    .mb-#{$i * 10} {margin-bottom: ($i * 10px);}
    .text-#{$i + 8} {font-size: ($i + 8px);}
  }
  .nowrap {white-space: nowrap;}
}

.float-wrap {
  position: fixed;
  right: 30px;
  bottom: 30px;
}
.page-break {
  display: none;
}
@media print {
  @page {
    size: auto;
    margin: 20mm;
  }
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
  body {background-color: #fff;}
  .print-page {
    margin: 0 auto;
    width: 100%;
    //page-break-inside: auto;
    //page-break-after: auto;
    //break-after: page;
    //page-break-before: always;
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
    page-break-before: always;
  }
}

//.table {
//  display: table;
//
//  position: relative;
//  width: 100%;
//  text-align: left;
//  border-radius: var(--rounded-box, 1rem);
//}
//.table :where(thead, tbody) :where(tr:not(:last-child)), .table :where(thead, tbody) :where(tr:first-child:last-child) {
//  border-bottom-width: 1px;
//  --tw-border-opacity: 1;
//  border-bottom-color: hsl(var(--b2) / var(--tw-border-opacity));
//}
//.table :where(th, td) {
//  padding-left: 1rem;
//  padding-right: 1rem;
//  padding-top: 0.75rem;
//  padding-bottom: 0.75rem;
//  vertical-align: middle;
//}